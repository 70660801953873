import "../App.css";
import {
  ChakraProvider,
  Stack,
  Heading,
  UnorderedList,
  Text,
  ListItem,
  Center,
  Image,
} from "@chakra-ui/react";
import BlogNavBar from "./blogNavBar";

function Blogs() {
  return (
    <ChakraProvider>
      <BlogNavBar />
      <Center width="98vw">
        <Center width={{ base: `70vw`, sm: `70vw`, lg: "50vw" }}>
          <Stack spacing={2} className={"py-10"}>
            <Heading as="h3" size="lg">
              Making Beats: Year 1 Reflection
            </Heading>

            <Text fontSize="md">August 20, 2023</Text>
            <br />
            <Text fontSize="md">
              I am planning to make my YouTube channel public this coming
              Monday, and as my weekly blog post, I wanted to write down some
              reflections and learnings, as well as my own weak spots as a
              producer who has still much to learn.
            </Text>

            <br />

            <Heading as="h3" size="md">
              Background
            </Heading>

            <Text fontSize="md">
              I formally started to learn how to make beats almost exactly one
              year ago. It was this class called HNUH300 which is the capstone
              for the University Honors program. The final project was
              open-ended and basically pitched as "accomplish a life goal of
              yours this semester and give a presentation about it at the end."
              I chose beat-making as my life goal, and I've continued to learn
              about it even after the class ended. My producer tag was also
              features vocals from the professor of that class.
              <br />
              <br />I do have a background in piano, having learned it since I
              was 6, so some of the music theory stuff comes a bit easier to me,
              as I'm not just clicking on stuff until it sounds good. Therefore,
              I think it has accelerated my progress, but I still have many
              aspects of music production to learn.
            </Text>
            <br />

            <Heading as="h3" size="md">
              VSTs I Use 🎧
            </Heading>

            <Text fontSize="md">
              These are the VSTs I use, outside of the default things provided
              in FL Studio. I'm sure I will acquire more as I get better, but
              here are the things that I used in my first year so far.
            </Text>

            <UnorderedList>
              <ListItem>
                Analog Lab V - I honestly abuse Analog Lab V and grab sounds
                from there for any instruments I want. I am very satisfied with
                their catalog of piano sounds as well as synths, and have used
                them quite extensively. However, I was not satisfied with their
                guitar selection (when I want to make more chill / RnB type
                beats) and also their trumpets. I think trumpets and trap beats
                go extremely well together, but I have not found a satisfying
                trumpet VST to use yet.
              </ListItem>
              <ListItem>
                Cymatics - I know this is super generic, but most of the drum
                sounds I use come from there, I think they're a great place as a
                beginner who does not want to put too much money into beatmaking
                just yet. I've been abusing the Cymatics Ambience kit too, as it
                provides a lot of good texture to an otherwise monotonic beat.
              </ListItem>
              <ListItem>
                Arcade - Lots of cool sounds, I mainly grab vocal chops from
                here though.
              </ListItem>
              <ListItem>
                Shaperbox 2 - The panning effect is the most used feature, as it
                adds a lot of movement to the beats. Synth pad + Shaperbox 2 has
                been one of my go-to workflows for making trap beats.
              </ListItem>
              <ListItem>
                FL Defaults - I've been using the default FL EQ, soft clipper,
                and reverb. I recently caved in and bought Valhalla Vintage Verb
                2, but for now, I'm staying cheap and just using the defaults,
                although I expect that will change as I go into Year 2.
              </ListItem>
            </UnorderedList>

            <br />

            <Heading as="h3" size="md">
              Biggest Producing Tricks That I Learned This Year 📝
            </Heading>

            <Text fontSize="md">
              Random list of (some rather obvious) things that I learned as
              someone completely new to beat-making
            </Text>

            <UnorderedList>
              <ListItem>
                Alt R for randomizer to make hi-hats have more bounce
              </ListItem>
              <ListItem>Alt U for chopper to make hi-hat rolls</ListItem>
              <ListItem>
                Using more open hats. I previously almost never used open hats
                and now I almost always do.
              </ListItem>
              <ListItem>
                Boost the 808. I used to be puzzled by why my 808s didn't sound
                as in-your-face as some other beats I hear. I've now learned to
                boost + clip the 808s.
              </ListItem>
              <ListItem>Panning hi-hats and snares for more movement</ListItem>
              <ListItem>
                Using hi-hats in triplets - sounds much more interesting
              </ListItem>
              <ListItem>Using ambience packs to add texture</ListItem>
            </UnorderedList>

            <br />
            <Heading as="h3" size="md">
              Goals For Year 2 🚀
            </Heading>
            <UnorderedList>
              <ListItem>
                Improve my 808s. My 808 patterns are basic and uninteresting.
                I've never quite mastered the art of sliding 808s, and I think
                when done properly, 808 patterns add so much to a song,
                especially with drill. So far, my 808s simply follow the chord
                root note and kick pattern, so I need to work on it for Year 2.
              </ListItem>
              <ListItem>
                Exploring some more of the FL defaults. I don't like adding new
                VSTs and plug-ins until I've fully explored the capabilities of
                what I have at my disposal, and there are some plug-ins in FL
                that I've never explored yet, such as the limiter, flanger, and
                chorus.
              </ListItem>
              <ListItem>
                Doing more drill. I don't have any drill beats I'm proud of even
                though I've been working on drill for a long time. I've always
                been distracted by going back to trap beats or (more recently)
                Jersey Club, and with Year 2, I want to create some drill beats
                that I can be proud of.
              </ListItem>
              <ListItem>
                Grow the channel to 100 subscribers, with one video reaching 1K
                views. I know that the beat-making market is a bit saturated
                right now, so I want to keep the expectations more realistic. I
                don't think you can game the system and immediately make the
                channel blow up, but I've done everything I can to improve the
                probability of success - such as nice thumbnails as well as tags
                in the description. I plan on dropping a NewJeans type beat as
                well as an Utopia type beat initially, and hopefully as they are
                both still somewhat hot topics, it will give the channel a
                slight boost from obscurity.
              </ListItem>
              <ListItem>
                Learn to sample right. Sampling is huge in Jersey club, and
                Metro also uses old-school samples to make some of the best trap
                beats. I've never really had the ear to immediately hear what
                chops of a sample would make good flips, and I think that comes
                with practice. Sampling more would be one of the goals I have.
              </ListItem>
              <ListItem>
                Finding my producer sound. Since it is my first year, I've tried
                to experiment with as many styles as possible (Jersey Club,
                trap, RnB). For Year 2, I want to find my own unique style and
                start becoming an expert in one style, instead of an OK-producer
                in various styles. I personally enjoy listening to trap the
                most, but that is also the most saturated market right now. I
                think NewJeans might be a good direction since I don't see many
                NewJeans type beats out there. I was also thinking of sampling
                old Chinese songs and flipping them into trap. A lot of
                producers flip old American songs, but I don't think anyone has
                done so with Chinese songs, so it could be a gap in the market I
                could take advantage of.
              </ListItem>
              <ListItem>
                Cut the over-reliance on hi-hats. My beats don't sound right
                until I add the hi-hats and hi-hat rolls. With Travis Scott's
                Utopia, some of the most musically-interesting tracks, such as
                Circus Maximus and HYAENA, both don't feature hi-hats, and
                that's something that I should play around with, using various
                other percs and rimshots to still produce an interesting groove.
              </ListItem>
            </UnorderedList>
          </Stack>
        </Center>
      </Center>
    </ChakraProvider>
  );
}

export default Blogs;
