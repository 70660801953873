import "../App.css";
import {
  ChakraProvider,
  Stack,
  Heading,
  UnorderedList,
  Text,
  ListItem,
  Center,
  Image,
} from "@chakra-ui/react";
import BlogNavBar from "./blogNavBar";
import pixel1 from "../images/blog/pixel1.png";
import pixel2 from "../images/blog/pixel2.png";
import pixel3 from "../images/blog/pixel3.png";

function Blogs() {
  return (
    <ChakraProvider>
      <BlogNavBar />
      <Center width="98vw">
        <Center width={{ base: `70vw`, sm: `70vw`, lg: "50vw" }}>
          <Stack spacing={2} className={"py-10"}>
            <Heading as="h3" size="lg">
              My Attempt to Moneyball My Way to Social Media Success (Week 1)
            </Heading>

            <Text fontSize="md">September 1, 2023</Text>
            <br />
            <Heading as="h3" size="md">
              Background
            </Heading>
            <Text fontSize="md">
              As my blog entry for this week, I wanted to reflect on something
              that I've spent the most time on during the first week of school.
              <br />
              <br />
              I've always done something music-related as a hobby - first
              playing the piano, and then transitioning into beat-making. As I
              am a senior with more time, I decided over the summer that I might
              as well just record and put stuff online, as there is no harm in
              doing so, but if by the off chance, something blows up online, it
              would be pretty cool.
              <br />
              <br />
              As a computer science major who has taken data science and has
              experience with data science projects, I want to take a deeper
              dive into the analytics that YouTube and TikTok give in order to
              gain insights into how I can improve my channels and hopefully
              grow the number of views and subscribers.
              <br />
              <br />I will ideally write more entries on this topic as the weeks
              go by, and as I push more videos (and get more data points). However,
              today I just want to do a quick reflection on my initial strategy and
              things I learned from the first week.
            </Text>

            <br />
            <Heading as="h3" size="md">
              Platform Strategy
            </Heading>

            <Text fontSize="md">
              There are two main types of content I want to push out - piano
              videos and hip hop beat videos. My initial strategy is to separate
              the two into different accounts, and to use multiple platforms to
              maximize my probability of success.
              <br />
              <br />
              For piano videos, I push them to Instagram and TikTok. I want to
              make use of Reels and TikTok, as I have definitely seen piano
              cover videos in my feed for both Reels and TikTok.
              <br />
              <br />
              For hip hop beats, I push them to SoundCloud and YouTube. Again,
              I've seen most type-beat channels get the most traction on those
              two platforms which is why I chose the two.
            </Text>

            <br />

            <Heading as="h3" size="md">
              Piano Content Strategy (Theory)
            </Heading>

            <Text fontSize="md">
              This is again all theory, and theory can only
              take you so far, so all this is subject to change, but here is my
              initial content strategy.
              <br />
              <br />
              For piano content, I want to have something somewhat more trendy.
              I picked NewJeans as the first piano mashup topic, which could
              hopefully gain some more traction, while I build a following.
              <br />
              <br />
              I do mashups instead of covers because I personally think it makes
              it more fun to arrange and learn, but I could also see this being
              a very unsustainable model, since I will have to learn three to
              four songs to create a mashup instead of just learning one song.
              <br />
              <br />
              For the second mashup I posted, I chose a Kdrama mashup, since
              that is the type of music that my Mom listens to a lot, so
              learning those songs by ear didn't take me too long.
            </Text>
            <br />

            <Heading as="h3" size="md">
              Beat Content Strategy (Theory)
            </Heading>

            <Text fontSize="md">
              I chose three different styles of beats to release at first. I
              released a more synth-y Playboi Carti x Travis Scott beat to
              hopefully ride the tailwinds of the Utopia hype train.
              <br />
              <br />
              I released a Drake x 21 Savage type beat as well, which is the
              main type of music I listen to, and is the type of beat that is
              the easiest for me to make. However, it is also the most saturated
              market space in terms of competitors as well.
              <br />
              <br />
              Lastly, I dropped a NewJeans type beat, which hopefully is trendy
              and does not have a lot of competitors in the space.
            </Text>

            <br />

            <Heading as="h3" size="md">
              Timeline
            </Heading>

            <UnorderedList>
              <ListItem>
                Dropped NewJeans Mashup (Insta Reels) - Aug 24 (Thurs) 12PM
              </ListItem>
              <ListItem>
                Dropped NewJeans Mashup (Tiktok) - Aug 27 (Sun) 3PM
              </ListItem>
              <ListItem>
                Dropped Beats (Travis Scott + Drake + NewJeans) (SoundCloud +
                YouTube) - Aug 27 (Sun) 3PM
              </ListItem>
              <ListItem>
                Dropped KDrama OST Mashup (Tiktok + Reels) - Aug 30 (Wed) 1PM
              </ListItem>
            </UnorderedList>

            <br />

            <Heading as="h3" size="md">
              View Stats (as of September 1)
            </Heading>

            <UnorderedList>
              <ListItem>
                NewJeans Piano Mashup (Reels: 265 Views, TikTok: 1231 Views)
              </ListItem>
              <ListItem>
                KDrama Piano Mashup (Reels: 199 Views, TikTok: 406 Views)
              </ListItem>
              <ListItem>
                Travis Scott x Playboi Carti - ELYSIUM (YouTube: 43 Views,
                SoundCloud: 2 Views)
              </ListItem>
              <ListItem>
                NewJeans - WHISPERED PROMISES (YouTube: 36 Views, SoundCloud: 2
                Views)
              </ListItem>
              <ListItem>
                Drake x 21 Savage - DIPLOMATIC IMMUNITY (YouTube: 37 Views,
                SoundCloud: 3 Views)
              </ListItem>
            </UnorderedList>

            <br />

            <Heading as="h3" size="md">
              Account Stats (as of September 1)
            </Heading>
            <UnorderedList>
              <ListItem>Reels - 21 Followers</ListItem>
              <ListItem>TikTok - 20 Followers</ListItem>
              <ListItem>YouTube - 11 Followers</ListItem>
              <ListItem>SoundCloud - 1 Followers</ListItem>
            </UnorderedList>

            <br />
            <Heading as="h3" size="md">
              Retro / Reflection 📝
            </Heading>
            <UnorderedList>
              <ListItem>
                The beats stuff is honestly underperforming a lot than expected.
                I think I was dead wrong about NewJeans being a viable market,
                and Utopia is not as trendy of a term as I thought. There are
                definitely production elements that I can improve on in terms of
                the beat quality, but it is still a bit disappointing the amount
                of views I got considering the amount of work I put in the
                thumbnails and editing some visuals to go with the beat. I do
                want to stick it out a bit before writing everything off, as I
                feel like the YouTube algorithm may reward channels with more
                consistent videos, and going off of the results of the first
                batch of releases may be too premature.
              </ListItem>
              <ListItem>
                To be brutally honest, SoundCloud is just not working, and I
                think I will focus my efforts on YouTube and growing that
                instead. I'm glad I tried it though, and for the next few
                releases, I'll still put on both since it's very easy on my part
                to do so.
              </ListItem>
              <ListItem>
                The NewJeans piano mashup did extremely well in comparison to
                the other videos, and I think it is due to it being trendy, and
                also being released in the right time (Sunday afternoon). The
                second TikTok had severely less traction, and I think
                content-wise there was not too much change. The biggest change was the 
                release time so I need to look into some more studies that people have 
                already done on the best time to release TikToks.
              </ListItem>
            </UnorderedList>
            <br/>

            <Heading as="h3" size="md">
             Future Content
            </Heading>
            <Text fontSize="md">
              For beats, even if the initial release was a disappointment, I think 
              it is actually in line with some of the newly starting beat channels on Youtube, 
              and I just have to stick with it and keep posting consistently. My hope is that 
              Drake is able to drop For All the Dogs soon, and I can start dropping 
              For All the Dogs type beats while it is still in trend to give the 
              channel some more visibility. I have a couple of Ice Spice type beats in my backpocket 
              which could be another trendy topic that could give me views. 
              <br/>
              <br/>
              For piano, I want to experiement more with shorter content. I don't know if 5 minute TikTok 
              videos are the move - so I might lower the number of songs in a mashup from 4 to 3. 
              I also want to capitalize off of trendy things - some things I'm thinking 
              about include the new NewJeans song that dropped (Beautiful Restriction) as well as 
              DPR Ian's new song that dropped a couple of weeks ago. I will adjust even more after 
              seeing the performance of different videos as well.

              </Text>
          </Stack>
        </Center>
      </Center>
    </ChakraProvider>
  );
}

export default Blogs;
