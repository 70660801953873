import "../Blog.css";
import {
  ChakraProvider,
  Stack,
  Card,
  Heading,
  CardBody,
  Text,
} from "@chakra-ui/react";
import BlogSideNav from "./blogSideNav";

const pageContent = [
  {
    title: "Reflecting On My Old Portfolio Site",
    date: "July 10, 2023",
    subtitle:
      "Looking back at the site I made freshmen year, and the things I could improve upon.",
    link: "oldSite",
  },
  {
    title: "My Movie Hall Of Fame Inductions 2023 (July)",
    date: "July 21, 2023",
    subtitle: "Bi-annual look at my favorite movies of the year",
    link: "favfilm2023_1",
  },
  {
    title: "How I Made My New Portfolio Site",
    date: "August 12, 2023",
    subtitle:
      "After creating and deploying my new site, the lessons I learned from this mini-project",
    link: "newSite",
  },
  {
    title: "Making the UMD Pixels Tracker",
    date: "August 17, 2023",
    subtitle:
      "The lessons I learned from working on the UMD Pixels Tracker over the summer",
    link: "pixels",
  },
  {
    title: "Making Beats: Year 1 Reflection",
    date: "August 20, 2023",
    subtitle: "The lessons I learned from first year making hip-hop beats",
    link: "beatsyear1",
  },
  {
    title: "My Attempt to Moneyball My Way to Social Media Success (Week 1)",
    date: "September 1, 2023",
    subtitle:
      "First week reflections of how my various music channels are doing",
    link: "moneyballsocialmediaweek1",
  },
];

function Blogs() {
  return (
    <ChakraProvider>
      <div className="drawer lg:drawer-open">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content flex flex-col p-4">
          <Stack spacing="4">
            <a
              className="btn btn-ghost normal-case text-xl appearOnMobile"
              href="/blog"
            >
              Andrew Yuan's Blog
            </a>
            {pageContent.map((page) => (
              <Card
                variant="elevated"
                onClick={() => {
                  window.location.href = `/#/blog/${page.link}`;
                }}
              >
                <CardBody>
                  <Heading size="md" className="pb-2">
                    {page.title}
                  </Heading>
                  <Text>{page.date}</Text>
                  <Text>{page.subtitle}</Text>
                </CardBody>
              </Card>
            ))}
          </Stack>
        </div>
        <div className="drawer-side">
          <BlogSideNav />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default Blogs;
