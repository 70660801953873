import "../App.css";
import {
  ChakraProvider,
  Stack,
  Heading,
  UnorderedList,
  Text,
  ListItem,
  Center,
} from "@chakra-ui/react";
import BlogNavBar from "./blogNavBar";

function Blogs() {
  return (
    <ChakraProvider>
      <BlogNavBar />
      <Center width="98vw">
        <Center width={{ base: `70vw`, sm: `70vw`, lg: "50vw" }}>
          <Stack spacing={2} className={"py-10"}>
            <Heading as="h3" size="lg">
              My Movie Hall Of Fame Inductions 2023 (July)
            </Heading>

            <Text fontSize="md">July 21, 2023</Text>
            <br />
            <Text fontSize="md">
              I watch a lot of movies and TV shows (for reference, I watched 91
              films last year), and I've always kept a list of my favorite
              movies of each year since 2014. Now that I'm using my personal
              site as a blog, I want to try to do this twice per year as a
              record of the movies I've watched and what I liked about them. My
              Hall of Fame has a very loose definition, and are basically films
              that I gave four-and-a-half stars and above. This is not
              necessarily a list of the best movies of 2023, since my personal
              enjoyment factors very heavily into the rankings. As of writing,
              it is July 21, and I've seen 30 movies from 2023 so far - here are
              my three sentence reviews for the six films I've ranked
              four-and-a-half stars and above.
            </Text>

            <br />
            <Heading as="h3" size="sm">
              6. Evil Dead Rise
            </Heading>

            <Text fontSize="md">
              While it is quite a jump-scare-focused horror movie, it is a wild
              and gory ride and a great continuation of the Evil Dead franchise,
              which has been one of my favorite horror franchises. The film is
              quite short at 96 minutes, and kept me glued to the screen at all
              times. The family involved is also surprisingly well-written, and
              chracters make (for the most part) educated and smart decisions in
              the face of danger, which I appreciated.
            </Text>

            <br />

            <Heading as="h3" size="sm">
              5. Extraction 2
            </Heading>

            <Text fontSize="md">
              This is a simple action movie that is executed extremely well.
              There is the 30 minute long one-shot sequence that was a joy to
              watch for the first time, and Chris Hemsworth is fantastic in the
              lead role. One of the kids is really annoying and incompetent, but
              ignoring the kid, this film is what I'd see as a perfect "for
              entertainment" action movie.
            </Text>

            <br />
            <Heading as="h3" size="sm">
              4. Guardians of the Galaxy Vol. 3
            </Heading>

            <Text fontSize="md">
              I used to be a very big Marvel fan, but the recent drop in quality
              has gotten me less excited for each new Marvel release. Guardians
              3 gets so much right though - each character gets a proper
              character arc (admittedly some are done better than others, but
              each character leaves the film changed, unlike the horrid mess
              that was Quantumania) and High Evolutionary is one of Marvel's
              best villains. My one issue with it is the pacing, as I think the
              film leaves you emotionally exhausted after the second act, and
              the third act suffers as a result.
            </Text>
            <br />

            <Heading as="h3" size="sm">
              3. The Covenant
            </Heading>

            <Text fontSize="md">
              The trailer looked horrendous but I was really pleasantly
              surprised. The action is shot really well, and the biggest
              standout for me is the film's ability to portray the paranoia of
              enemies slowly closing in, which is not something the other action
              films on this list do. My only complaint is that the ending seemed
              a bit anticlimatic but otherwise a really good action film.
            </Text>
            <br />

            <Heading as="h3" size="sm">
              2. Missing
            </Heading>

            <Text fontSize="md">
              I highly recommend the film if you are a fan of mystery thrillers.
              This movie's gimmick is that it all takes place on the main
              character's screen, and the film's usage of digital technology as
              part of the "detective sleuthing" was very creative. There are
              lots of twists that are unexpected while still being properly set
              up, and the characters make competent and smart decisions
              throughout, which is always a plus for me.
              <br />
            </Text>
            <br />

            <Heading as="h3" size="sm">
              1. John Wick: Chapter 4
            </Heading>

            <Text fontSize="md">
              From my list so far, you can probably tell that I am biased
              towards well-made action movies, and this movies boasts some of
              the best choreographed action scenes of not only the year, but the
              decade. The cinematography (like the scenes in Japan and the
              negotiation scene in Paris) is surprisingly good for what you'd
              expect to be a mindless action film, and the action sequences are
              very creative (Scott Adkins in a fat suit, the staircase, the
              one-shot shotgun sequence).
              <br />
            </Text>

            <br />

            <Heading as="h3" size="sm">
              Honorable Mentions
            </Heading>

            <Text fontSize="md">
              I enjoyed these movies a lot as well, but didn't quite make 4.5
              stars for me.
              <br />
              <br />
              <UnorderedList>
                <ListItem>Spider-Man: Across the Spider-verse</ListItem>
                <ListItem>Blackberry</ListItem>
                <ListItem>Creed 3</ListItem>
                <ListItem>Air</ListItem>
              </UnorderedList>
            </Text>
          </Stack>
        </Center>
      </Center>
    </ChakraProvider>
  );
}

export default Blogs;
