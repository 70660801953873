import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Link,
} from "@chakra-ui/react";

export default function AllProjects() {
  return (
    <div>
      <h1 class="text-3xl p-3">All Projects</h1>
      <div class="width-ninety-percent">
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <h1 class="text-2xl p-3">01 crApp</h1>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <h4>
                <b>The Problem</b>
              </h4>
              <p>
                There are vast differences in quality between restrooms in
                academic buildings across UMD's campus. Many students have
                unfortunately learned this the hard way. Wouldn't it be great to
                have some way to see how your peers have rated a restroom before
                deciding to go there?
              </p>
              <br />
              <h4>
                <b>The Solution</b>
              </h4>
              <p>
                {" "}
                I used <b>Flutter/Dart</b> to create a mobile application
                similar to Yelp in its review-system. The home page of the app
                shows a map (through using Google Maps API) along with markers
                for each UMD academic building. Clicking on the markers bring up
                information about each restroom, including links to reviews.
                Users will also have the ability to leave reviews, and a
                not-yet-implemented feature is for users to favorite certain
                locations. There is also a button present on the home screen,
                and when pressed, will route you to the best restroom depending
                on factors such as review ratings and distance.
                <br />
                <br />
                <Link
                  color="teal.500"
                  href="https://github.com/andrewyuantw/CrApp_Public"
                >
                  See the project here ...
                </Link>
              </p>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <h1 class="text-2xl p-3">02 MemAid</h1>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <h4>
                <b>The Problem</b>
              </h4>
              <p>
                Dementia patients are unable to recall faces and conversations,
                and our team at the Bitcamp Hackathon wanted to find a way to
                utilize various cloud APIs available to us to solve this
                problem.{" "}
              </p>
              <br />
              <h4>
                <b>The Solution</b>
              </h4>
              <p>
                {" "}
                We used WebRTC to stream a phone's camera stream to a RTC
                server. From our server, we used facial recognition to identify
                the people in the video stream. From there, we used Google
                Cloud's speech-to-text API to obtain a transcription of the
                conversation, and then Google Cloud's Natural Language API to
                extract key topics of the conversation. We then stored this
                information into local JSON files. From then on, if the app
                identifies a face the app has already seen, it would use Google
                Cloud's text-to-speech to read out the person's name and the
                topics of your last conversation. We built the frontend mobile
                app with <b>Flutter</b> and <b>Dart</b>.
                <br />
                <br />
                This project won Best Social Good Hack, Best Use of Google Cloud
                Hack, and also the MLH Special Recognition award at Bitcamp
                2022.
                <br />
                <br />
                <Link
                  color="teal.500"
                  href="https://devpost.com/software/memaid"
                >
                  See the project here ...
                </Link>
              </p>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <h1 class="text-2xl p-3">
                      03 Monte Carlo Tree Search ConnectFour
                    </h1>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <h4>
                <b>The Problem</b>
              </h4>
              <p>
                {" "}
                I kept losing to my friends in ConnectFour. As a computer
                science enthusiast (and someone who doesn't like losing), I
                decided I wanted to take inspiration from programs like Google's
                AlphaGo and build a program would give me the best move for any
                ConnectFour game board.{" "}
              </p>
              <br />{" "}
              <h4>
                <b>The Solution</b>
              </h4>
              <p>
                I first self-learned machine learning and game theory concepts
                in order to build out the logic necessary to build my desired
                program. After some research, I implemented the Monte Carlo Tree
                Search algorithm as well as Upper Concentration Trees using{" "}
                <b>Java</b>, and my program would explore various possibilities
                efficiently, keeping track of the winning record of each move,
                before returning the best possible move given the game board.
                <br />
                <br />
                <Link
                  color="teal.500"
                  href="https://github.com/andrewyuantw/MonteCarloConnectFour"
                >
                  See the code here ...
                </Link>
              </p>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <h1 class="text-2xl p-3">04 Werewolf.io</h1>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <h4>
                <b>The Problem</b>
              </h4>
              <p>
                While netgames.io provided great online versions of classical
                games such as Avalon or Spyfall, it did not have an online
                version of Werewolf, a social game that me and my friends
                enjoyed. With the COVID-19 pandemic, we were unable to play the
                game in person, and needed to find a way to play the game
                virtually.
              </p>
              <br />
              <h4>
                <b>The Solution</b>
              </h4>
              <p>
                Me and my friend worked over the summer of 2021 to create a
                multiplayer web game using Node JS and socket.io. For normal
                in-person Werewolf games, one player needs to be the Narrator of
                the game, keeping track of everyone's role and providing
                instructions for different characters. However, our online
                version completely automates this role with our code's
                client-server architecture, making gameplay even more enjoyable.
                <br />
                <br />
                <Link
                  color="teal.500"
                  href="https://github.com/andrewyuantw/werewolf"
                >
                  See the code here ...
                </Link>
              </p>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <h1 class="text-2xl p-3">
                      05 Can't Spell Lakers Without An "L"
                    </h1>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <h4>
                <b>The Problem</b>
              </h4>
              <p>
                I was born in California and grew up watching Kobe play, so I
                have been a long-time Lakers fan. The Lakers were NBA champions
                during the 2019-20 season, but just two years later (2021-22
                season), were not even able to make the playoffs. It was a big
                embarassment for the Lakers franchise as well as its fanbase,
                and I wanted to take a more statistically-rigorous look into why
                the Lakers completely imploded during the season.
              </p>
              <br />
              <h4>
                <b>The Solution</b>
              </h4>
              <p>
                I worked with other classmates and used{" "}
                <b>Python's Pandas and matplotlib </b> to analyze season data,
                player statistics, game winrates, etc. to try to find potential
                reasons behind the franchise's dramatic fall from grace in just
                two years. We investigated four different hypotheses to figure
                out the main reasons for the franchise's failure, and you can
                see our writeup below.
                <br />
                <br />
                <Link
                  color="teal.500"
                  href="https://www.andrewyuantw.com/LakersAnalysis"
                >
                  Link here ...
                </Link>
              </p>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
