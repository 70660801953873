function BlogSideNav() {
  return (
    <ul className="menu p-4 w-80 h-full bg-base-200 text-base-content">
      <a className="btn btn-ghost normal-case text-xl" href="/#/blog">
        Andrew Yuan's Blog
      </a>
      <br />
      <p>
        My attempt at keeping track of the things I've learned and other random
        thoughts.
      </p>
      <br />
      <h3>Categories</h3>
      <ul className="menu bg-base-200 w-56 rounded-box">
        <details open>
          <summary>&nbsp;Tech Projects</summary>
          <ul>
            <li>
              <a href="/#/blog/oldSite">Reflecting on My Old Portfolio Site</a>
            </li>
            <li>
              <a href="/#/blog/newSite">How I Made My New Portfolio Site</a>
            </li>
            <li>
              <a href="/#/blog/pixels">Making the UMD Pixel Tracker</a>
            </li>
          </ul>
        </details>
        <details open>
          <summary>&nbsp;Movies</summary>
          <ul>
            <li>
              <a href="/#/blog/favfilm2023_1">
                My Movie HoF Inductions 2023 (July)
              </a>
            </li>
          </ul>
        </details>
        <details open>
          <summary>&nbsp;Music Production</summary>
          <ul>
            <li>
              <a href="/#/blog/beatsyear1">Making Beats: Year 1 Reflection</a>
            </li>
            <li>
              <a href="/#/blog/moneyballsocialmediaweek1">Moneyballing Music Channels 1</a>
            </li>
          </ul>
        </details>
      </ul>
    </ul>
  );
}

export default BlogSideNav;
