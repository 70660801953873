import React from "react";
import {
  Box,
  IconButton,
  useBreakpointValue,
  Heading,
  CardHeader,
  Text,
  Card,
  CardBody,
  Container,
  Grid,
  GridItem,
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
// And react-slick as our Carousel Lib
import Slider from "react-slick";
import pixels from "../images/pixels.png";
import lakersLogo from "../images/lakers.png";
import brainLogo from "../images/memaid.webp";
import edufuturoLogo from "../images/edufuturo.png";
import werewolf from "../images/werewolf.png";
import connectfour from "../images/connectfour.png";

// Settings for the slider
const settings = {
  dots: false,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  adaptiveHeight: true,
};

export default function FeaturedProjects() {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "40px" });

  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      title:
        "MemAid - Best Google Cloud Hack and Best Social Good Hack at Bitcamp",
      text: "Mobile application utilizing Google Cloud NLP APIs and facial recognition APIs in order to transcribe conversations for people diagnosed with dementia. The application also uses facial recognition and plays back the transcribed conversation to the user when a face from a past conversation is detected.",
      tools: ["Flutter", "Dart"],
      link: "https://devpost.com/software/memaid",
      image: brainLogo,
    },
    {
      title: "Edufuturo",
      text: "A Workday-esque web application tailored for non-profits made for the non-profit Edufuturo as part of Hack4Impact-UMD's team. Web application allows case managers to easily create and keep track of cases, beneficiaries, and services regarding their organization.",
      tools: ["React", "Typescript", "Firebase"],
      link: "https://edufuturo-51eec.web.app/",
      image: edufuturoLogo,
    },
    {
      title: "Can't Spell Lakers Without an L",
      text: "Data analysis project that analyzed the reasons behind the Laker's horrendous 2021 - 2022 NBA season. Project investigated possible reasons, such as LeBron being injured for a majority of the season, the team trading away defense-oriented players, and Russell Westbrook's presence.",
      tools: ["Python", "Matplotlib"],
      link: "https://www.andrewyuantw.com/LakersAnalysis",
      image: lakersLogo,
    },
    {
      title: "Werewolf.io",
      text: "Online multi-player game of the party game Werewolf. Usually the game is played in-person, but COVID happened, so me and a friend built an online version instead.",
      tools: ["Node.js", "Javascript"],
      link: "https://github.com/andrewyuantw/werewolf",
      image: werewolf,
    },
    {
      title: "ConnectFour Strategist",
      text: "I got tired of losing at ConnectFour so I wrote a program that would efficiently play out possible moves and give me the move that would best increase my probability of winning.",
      tools: ["Java"],
      link: "https://github.com/andrewyuantw/MonteCarloConnectFour",
      image: connectfour,
    },
    {
      title: "Hack4Impact-UMD Pixel Tracker",
      text: "Web application that allows Hack4Impact-UMD members to log in and view their Pixel earnings and ranking. Pixels are earned by attending Hack4Impact-UMD events, with final Pixels used in a raffle for end-of-year giveaways.",
      tools: ["Next.js", "React"],
      link: "/#/blog/pixels",
      image: pixels,
    },
  ];

  return (
    <div class="greyBkg">
      <Box
        position={"relative"}
        width={"full"}
        overflow={"overlay"}
        height={{ base: `100vh`, sm: `100vh`, lg: "61vh" }}
      >
        {/* CSS files for react-slick */}
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        {/* Left Icon */}
        <IconButton
          aria-label="left-arrow"
          variant="ghost"
          position="absolute"
          left={side}
          top={top}
          transform={"translate(0%, -50%)"}
          zIndex={2}
          onClick={() => slider?.slickPrev()}
        >
          <BiLeftArrowAlt size="40px" />
        </IconButton>
        {/* Right Icon */}
        <IconButton
          aria-label="right-arrow"
          variant="ghost"
          position="absolute"
          right={side}
          top={top}
          transform={"translate(0%, -50%)"}
          zIndex={2}
          onClick={() => slider?.slickNext()}
        >
          <BiRightArrowAlt size="40px" />
        </IconButton>
        <Slider {...settings} ref={(slider) => setSlider(slider)}>
          {cards.map((card, index) => (
            <Box
              key={index}
              position="relative"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
            >
              <Container
                size="container.lg"
                position="relative"
                backgroundPosition="center"
                maxW={"80vw"}
                height={{ base: `99vh`, sm: `99vh`, lg: "60vh" }}
                padding={"0vh"}
                className={"noPadding"}
              >
                <Card
                  position="absolute"
                  top="50%"
                  transform="translate(0, -50%)"
                  width="100%"
                >
                  <CardHeader>
                    <Heading size="md">{card.title}</Heading>
                  </CardHeader>
                  <CardBody class="gridLayout">
                    <Grid
                      templateAreas={{
                        base: `"IMAGE" "CONTENT"`,
                        sm: `"IMAGE" "CONTENT"`,
                        lg: `"CONTENT IMAGE"`,
                      }}
                      gridTemplateColumns={{
                        base: "1fr",
                        sm: "1fr",
                        lg: "6fr 4fr",
                      }}
                      gridTemplateRows={{
                        base: `"1fr" "2fr"`,
                        sm: `"1fr" "2fr"`,
                        lg: "1fr",
                      }}
                      gap="50px"
                    >
                      <GridItem area={"CONTENT"}>
                        <div class="box">
                          {card.tools.map((tool) => (
                            <div>{tool}</div>
                          ))}
                        </div>
                        <div class="paddingCardBody">
                          <Text>{card.text}</Text>
                        </div>
                        <br />
                        <div class="cardLink">
                          <a class="customButton" href={card.link}>
                            See Link
                          </a>
                        </div>
                      </GridItem>
                      <GridItem area={"IMAGE"}>
                        <img
                          src={card.image}
                          class="cardPic"
                          alt="project screenshot"
                        />
                      </GridItem>
                    </Grid>
                  </CardBody>
                </Card>
              </Container>
            </Box>
          ))}
        </Slider>
      </Box>
    </div>
  );
}
