import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

import { Link } from "@chakra-ui/react";
import h4iLogo from "../images/hack4impact.png";
import questLogo from "../images/quest.png";
import samLogo from "../images/sam.png";
import xrLogo from "../images/xr.png";
import umdLogo from "../images/umd.webp";

export default function Extracurriculars() {
  return (
    <div>
      <h1 class="text-3xl p-3">On-Campus Involvement</h1>
      <div class="width-ninety-percent">
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={h4iLogo} alt="h4ilogo" />

                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        Hack4Impact
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                <i>Aug 2021 - Present</i>
                <br />
                Positions held: Technical Team Lead, Software Developer,
                Director of Recruitment
              </p>
              <br />
              <p>
                <Link color="teal.500" href="https://umd.hack4impact.org/">
                  Hack4Impact{" "}
                </Link>{" "}
                is a student-run organization that partners with non-profits to
                build software solutions that the non-profits need. I've been a
                part of the organization for two years now, and have served many
                roles during my time.
                <br />
                <br />
                At first, I was a software developer on the{" "}
                <Link color="teal.500" href="https://2unstoppable.org/">
                  2Unstoppable
                </Link>{" "}
                team, helping a nonprofit organization dedicated to helping
                women with cancer find fitness buddies. We built a full-stack
                application that matched people together based on their profiles
                (think Tinder but for finding fitness buddies instead). The
                application had a <b>React</b> frontend and a <b>PostgreSQL</b>{" "}
                backend, and I was able to work on both during the year-long
                project.
                <br />
                <br />
                I was served on the club's executive board as Director of
                Recruitment, and I was in charge of implementing a recruitment
                plan to screen and onboard new members from all across campus. I
                also was responsible for reviewing written applications, and
                leading interviews for team roles.
                <br />
                <br />
                Most recently, I was one of two technical leads for the{" "}
                <Link color="teal.500" href="https://edu-futuro.org/">
                  Edu-futuro
                </Link>{" "}
                team. One valuable thing that Edu-futuro provides is that case
                managers under Edu-futuro are able to match local services to
                beneficiaries that come to Edu-futuro. Edu-futuro was in need of
                an easy way to keep track of all the cases, and they were
                organizing everything on a single spreadsheet before our team
                came onboard. I led a team of eight software engineers, and
                worked with PMs and designers on the team to deliver a
                Workday-esque web application to help facilitate and organize
                beneficiaries and services to streamline their case management
                process. The web application was built with a <b>React</b>{" "}
                frontend and a <b>Firebase</b> backend, and as technical lead, I
                was in charge of code reviews and merging pull requests outside
                of regular sprint tasks.
              </p>
              <br />
              <p> Tasks that I worked on:</p>
              <UnorderedList>
                <ListItem>
                  Revamped the frontend UI using <b>React</b>, <b>HTML</b>, and{" "}
                  <b>Javascript</b> to match Figma mockups drawn by UI designers
                </ListItem>
                <ListItem>
                  Integrated backend communication with <b>PostgreSQL</b> for
                  profile storage and user messaging (2Unstoppable)
                </ListItem>
                <ListItem>
                  Set up and used <b>Firebase</b> for user authentication, data
                  storage, and file storage (Edufuturo)
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={umdLogo} alt="umdLogo" />
                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        CMSC389O Course Facilitator
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                <i>Aug 2021 - Present</i>
                <br />
                Positions held: Course Instructor
              </p>
              <br />
              <p>
                Since Fall 2022, I've been an instructor for the student-led{" "}
                <i>Mastering the Coding Interview</i> class offered at the
                University of Maryland. The course aims to help students better
                prepare for coding interviews, and covers major data structure
                and algorithm concepts. Some of my duties include:
              </p>
              <br />

              <UnorderedList>
                <ListItem>
                  Teach students Data Structure + Algorithm concepts and common
                  interview questions using Python
                </ListItem>
                <ListItem>
                  Prepared class material and led weekly classes for
                  approximately 25 students
                </ListItem>
                <ListItem>
                  Provided feedback on code readability and efficiency on graded
                  assignments
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={questLogo} alt="quest logo" />

                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        QUEST
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                <i>Aug 2021 - Present</i>
              </p>
              <br />
              <p>
                I am part of the{" "}
                <Link color="teal.500" href="https://www.rhsmith.umd.edu/quest">
                  QUEST
                </Link>{" "}
                (Quality Enhancement Systems and Teams) Honors Program in Cohort
                38. It is an interdisciplinary program focused on helping
                students gain hands-on experiences through experiential learning
                projects and collaborating with industry partners. During the
                duration of my time in the QUEST program, I've managed to use my
                abilities in tech to work with other cohort members to provide
                value to various clients.{" "}
              </p>
              <br />
              <p>Clients / Projects I've worked with:</p>
              <UnorderedList>
                <ListItem>
                  <Link
                    color="teal.500"
                    href="https://recwell.umd.edu/programs-activities/adventure-program"
                  >
                    UMD's RecWell Adventure Wall
                  </Link>
                  : I worked with the RecWell Adventure Wall to improve and
                  streamline their certification process for belaying. I was
                  able to create Google Sheet formula automations for the
                  climbing staff, simplifying a complicated tracking system into
                  one system where employees only need to use one Google form to
                  input data, and the data is automatically processed and sorted
                  in the desired form with no extra work needed.
                </ListItem>
                <ListItem>
                  <Link color="teal.500" href="https://airfaresnacks.com/">
                    Airfare
                  </Link>
                  : I worked with Airfare, a company dedicated to providing
                  healthy snacks for on-the-go commuters. Our QUEST team
                  provided strategies for better customer retention, as well as
                  provided insights into existing customer trends through
                  analyzing data exported from the company's Shopify account. I
                  utilized <b>Python</b> and its <b>pandas</b> and{" "}
                  <b>matplotlib</b> libraries in order to effectively analyze
                  the provided data.
                </ListItem>
                <ListItem>
                  Scout360 : I worked with our QUEST team in order to prototype
                  a mobile app designed to help collegiate basketball
                  recruitment in Cameroon. The prototype was created through{" "}
                  <b>Figma</b> after conducting background research and
                  identifying pain points experienced in the current system.
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={samLogo} alt="sam logo" />
                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        Sigma Alpha Mu Fraternity
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                <i>Aug 2021 - Present</i>
              </p>
              <br />
              <p>
                I am part of Sigma Alpha Mu Fraternity, and some of the chair
                positions I hold within the fraternity include:
              </p>
              <UnorderedList>
                <ListItem>
                  Diversity & Inclusion Chair: Promoted diversity & inclusion
                  within our chapter, attended monthly meetings with UMD's
                  Interfraternity Council (IFC){" "}
                </ListItem>
                <ListItem>
                  Expectations Chair: Ensured our chapter has met all the
                  requirements and expectations listed by IFC, organized events
                  such as sexual assault prevention workshops and alcohol/drug
                  prevention seminars
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={xrLogo} alt="xr logo" />
                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        Extended Reality Club
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                <i>Aug 2020 - Present</i>
                <br />
                Positions held: Treasurer, Club Mentor
              </p>
              <br />
              <p>
                As part of the XR Club, I have worked with Unity and led
                workshops on topics such as Microsoft's Mixed Reality Toolkit
                (MRTK) as well as new technologies such as WebXR for fellow club
                members. Some things that I've worked on include:
              </p>
              <UnorderedList>
                <ListItem>
                  <Link
                    color="teal.500"
                    href="https://github.com/andrewyuantw/ZombieSurvival"
                  >
                    ZombieSurvival
                  </Link>{" "}
                  - a game created using Unity and C# set in a zombie
                  apocalypse.{" "}
                </ListItem>
                <ListItem>
                  NASA SUITS - an Augmented Reality interface coded using Unity
                  and C# to aid astronauts on lunar missions.{" "}
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
