import "./App.css";
import { useEffect } from "react";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import LandingPage from "./components/LandingPage";

import Skills from "./sections/skills";
import Experiences from "./sections/experiences";
import FeaturedProjects from "./sections/featuredProjects";
import Extracurriculars from "./sections/extracurriculars";
import AllProjects from "./sections/allProject";
import Blogs from "./blog/blog";
import BlogsOldSite from "./blog/oldSite";
import BlogsFavFilmPart1 from "./blog/favFilm2023_1";
import BlogsNewSite from "./blog/newSite";
import BlogsPixel from "./blog/pixels";
import BlogsBeatYear1 from "./blog/beatsYear1";
import MoneyBallWeek1 from "./blog/moneyball1";

import { HashRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

import ReactGA from "react-ga";
import React from "react";
const TRACKING_ID = "G-PVR84R10FN"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blog/oldSite" element={<BlogsOldSite />} />
        <Route path="/blog/newSite" element={<BlogsNewSite />} />
        <Route path="/blog/favfilm2023_1" element={<BlogsFavFilmPart1 />} />
        <Route path="/blog/pixels" element={<BlogsPixel />} />
        <Route path="/blog/beatsyear1" element={<BlogsBeatYear1 />} />
        <Route
          path="/blog/moneyballsocialmediaweek1"
          element={<MoneyBallWeek1 />}
        />
      </Routes>
    </HashRouter>
  );
}

function MainPage() {
  return (
    <ChakraProvider>
      <NavBar />
      <LandingPage />

      <div id="skills">
        <Skills />
      </div>

      <div id="FeaturedProjects">
        <FeaturedProjects />
      </div>

      <div id="Experiences">
        <Experiences />
      </div>

      <div id="Extracurriculars">
        <Extracurriculars />
      </div>

      <div id="AllProjects">
        <AllProjects />
      </div>

      <div class="height10vh"></div>

      <Footer />
    </ChakraProvider>
  );
}

export default App;
