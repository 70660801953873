import "../App.css";
import {
  ChakraProvider,
  Stack,
  Heading,
  UnorderedList,
  Text,
  ListItem,
  Center,
  Image,
} from "@chakra-ui/react";
import BlogNavBar from "./blogNavBar";
import pixel1 from "../images/blog/pixel1.png";
import pixel2 from "../images/blog/pixel2.png";
import pixel3 from "../images/blog/pixel3.png";

function Blogs() {
  return (
    <ChakraProvider>
      <BlogNavBar />
      <Center width="98vw">
        <Center width={{ base: `70vw`, sm: `70vw`, lg: "50vw" }}>
          <Stack spacing={2} className={"py-10"}>
            <Heading as="h3" size="lg">
              Making the UMD Pixel Tracker
            </Heading>

            <Text fontSize="md">August 17, 2023</Text>
            <br />
            <Heading as="h3" size="md">
              Background
            </Heading>
            <Text fontSize="md">
              I am part of an on-campus organization called Hack4Impact, and to
              help incentivize members to be more active in club-wide
              activities, a system was implemented where members can earn
              "pixels" for each event/activity attended, and the pixels would
              then be translated into tickets that can be used for an
              end-of-year raffle for prizes. Last year was the first year the
              system was implemented, and there was not a lot of transparency
              with the amount of pixels a member has. Therefore, over the
              summer, I worked with the Director of Events of the club, who
              happened to be my co-tech-lead on a previous project, to make a
              web app that would allow users to keep track of their "pixels".
            </Text>

            <br />

            <Heading as="h3" size="md">
              Tech Stack 👨‍💻
            </Heading>

            <Heading as="h3" size="sm">
              1. Payload CMS
            </Heading>

            <Text fontSize="md">
              I wasn't familiar with this tool before, and this was my first
              time using it. I found it quite easy to pick up, with some good
              documentation as well. For those unfamiliar as well, Payload CMS
              is an open-source tool that is especially good at creating admin
              interfaces with friendly UIs. Since our app pretty much involves
              just a backend where we can easily enter information about events,
              members, and attendance, and a simple frontend where users can log
              in and see a record of their pixels, Payload was great in getting
              us set up quickly.
              <br />
              <br />
              All we needed to do was define certain fields related to each
              entity (Member, Event, etc.), and Payload CMS also has a great
              "relations" feature, which helped us define relationships between
              different entities in our datastore easily.
              <br />
              <br />
              Another great thing about Payload is because it was initially
              designed to be used to create admin UIs, it was very easy to scope
              access to different users, requiring basically just a one-line
              change using the CMS.
              <br />
              <br />
              Below is a screenshot of what the admin panel made with Payload
              looks like.
            </Text>
            <br />

            <Image src={pixel2} />

            <br />
            <Heading as="h3" size="sm">
              2. Next.js
            </Heading>

            <Text fontSize="md">
              There was no particular reason behind this choice, besides the
              fact that the default project that Payload provides uses Next.js
              and we didn't have any outstanding reasons to deviate from that.
            </Text>

            <br />

            <Heading as="h3" size="sm">
              3. NextUI
            </Heading>

            <Text fontSize="md">
              I used the NextUI component library to quickly get the frontend
              setup, and I found NextUI's documentation to be easy to navigate
              as well. The buttons, nav bar, and cards, were all taken from
              NextUI and then tweaked. NextUI's tables also provided pagination
              out-of-the-box, so I did not have to write extensive logic to get
              pagination working, which I appreciated.
              <br />
              <br />
              Below is a screenshot of the log in screen.
            </Text>
            <br />

            <Image src={pixel1} />

            <br />

            <Text fontSize="md">
              Below is a screenshot of the pixel logs a user will see once
              logged in.
            </Text>

            <Image src={pixel3} />

            <br />
            <Heading as="h3" size="md">
              Technical Details 💻
            </Heading>
            <br />

            <Heading as="h3" size="sm">
              1. Authentication
            </Heading>

            <Text fontSize="md">
              I did not code this part of the project, so I am not an expert,
              but I did review and merge this PR. Since our club uses Slack to
              communicate, we used Slack's provided auth as our login. One
              annoying thing (from Slack's side) is that it will always ask
              users for permission, even if the user has already clicked "Allow
              Permissions" previously. To get around this, we stored login
              sessions that expire, and users would bypass the Slack Auth part
              as long as their session has not expired.
            </Text>

            <br />

            <Heading as="h3" size="sm">
              2. Calculating Total Pixels
            </Heading>

            <Text fontSize="md">
              Initially, we had just set up the admin panel to store (for each
              member) the events attended. Under each events entity, there would
              be an attribute of how pixels the event was worth. Under these
              conditions, calculating rank among all members would require a
              costly recomputation of pixels every single time. To improve this,
              we cached the pixels for each member. This means each time a
              member's total computation is recalculated, the result is stored.
              Afterwards, we use hooks to only trigger recalculations whenever
              an event's associated pixel value has changed, or if an event's
              attendance has changed. This allows us to quickly retrieve and
              also rank members by their pixel amount.
            </Text>

            <br />

            <Heading as="h3" size="sm">
              3. Easy Way to Input Attendance
            </Heading>

            <Text fontSize="md">
              Another consideration was how to best input attendance for an
              event. We defined a relationship field between Member entities and
              Event entities, and relationship fields translate to dropdown
              menus in Payload's Admin UI. Under these conditions, someone would
              need to manually select all the Members who attended the event
              from the dropdown, and this would be a very inefficient thing, as
              there could be up to 50 attendees for an event.
              <br />
              <br />
              One solution considered was to use something like Make to create
              an automation script. The club usually use Google Forms to track
              attendance, and the idea was to use Make to trigger a change in
              our datastore every time a new response to a Google Form was
              detected. This was quite financially costly, since Make doesn't do
              automations for free, and also this would require some extra work
              on whoever created the event to make sure to create a new
              automation for every single event's Google Form.
              <br />
              <br />
              The settled approach is a bit smarter but is a bit janky. What I
              did was added an attendees_for_input field to the Event entity,
              that would take in a string value. The idea is that after an
              event, someone would copy over the list of emails that the
              attendees used for attendance on the Google Form, and paste it
              into this field. When copying over responses, the pasted result
              would default to space-separated values. Afterwards, I use a hook
              so that whenever attendees_for_input is changed, I split the value
              by space characters, do a lookup by email in the Member datastore,
              and use Payload's update function to update the Event entity to
              reflect the attendance.
              <br />
              <br />
              This requires no additional financial cost, and also allows
              whoever is inputting attendance to do so with ease - they just
              copy the emails and paste it into the attendees_for_input field.
            </Text>

            <br />
            <Heading as="h3" size="md">
              Retro / Reflection 📝
            </Heading>
            <UnorderedList>
              <ListItem>
                Using Payload CMS and NextUI made life so much easier - instead
                of doing mundane tasks like making sure the tables were
                paginated or the hassle of creating a nice-looking UI for the
                backend, these tasks were done immediately and the focus was
                turned on the more interesting tasks.
              </ListItem>
              <ListItem>
                Should have enforced linting on every PR - while we did have
                ESLint installed, there were no auto-ESLint-checks enforced on
                the PR level by Github, so sometimes, simple formatting changes
                would slip into the next PR, and it would muddy the actual
                reasoning of the PR, since ideally each PR is atomic.
              </ListItem>
              <ListItem>
                I thought the way we did attendance was a bit janky, and I came
                to this solution after looking at some of the discussions on
                Payload's Github since it is open-source. In the future, I think
                I will definitely utilize Github's discussion sections for
                inspiration on how to do a task, because almost certainly,
                somebody before me has tried to do the same thing before.
              </ListItem>
            </UnorderedList>
          </Stack>
        </Center>
      </Center>
    </ChakraProvider>
  );
}

export default Blogs;
