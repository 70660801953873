import "../App.css";
import { ChakraProvider, Stack, Heading, Text, Center } from "@chakra-ui/react";
import BlogNavBar from "./blogNavBar";

function Blogs() {
  return (
    <ChakraProvider>
      <BlogNavBar />
      <Center width="98vw">
        <Center width={{ base: `70vw`, sm: `70vw`, lg: "50vw" }}>
          <Stack spacing={2} className={"py-10"}>
            <Heading as="h3" size="lg">
              How I Made My New Portfolio Site
            </Heading>

            <Text fontSize="md">August 12, 2023</Text>

            <br />

            <Text fontSize="md">
              After my internship ended, I finally had some time to go through
              my old website and to revamp it. I will try to keep this guide
              short, and also make note of the lessons I learned from doing it.
            </Text>

            <br />

            <Heading as="h3" size="md">
              Tech Stack 👨‍💻
            </Heading>
            <br />

            <Heading as="h3" size="sm">
              1. React
            </Heading>

            <Text fontSize="md">
              There was no real reason behind this besides it being the frontend
              framework that I'm most used to, and my general existing knowledge
              of React would make development faster.
            </Text>
            <Heading as="h3" size="sm">
              2. Tailwind CSS and DaisyUI
            </Heading>

            <Text fontSize="md">
              I thought Tailwind CSS was a great way to quickly get things
              going, as adding things like "4px padding to the side" became
              trivial, and I did not have to bounce in between my .jsx files and
              my .css files to make simple stylistic changes. DaisyUI was a
              component library I had read about, and looking at their
              documentation, there were some nice components (such as the nav
              bar and footer) that I just took and retooled.
            </Text>
            <Heading as="h3" size="sm">
              3. ChakraUI
            </Heading>

            <Text fontSize="md">
              While DaisyUI had some great components, I wanted something a
              little different than DaisyUI's Accordion component for my
              experience/project section, so I found another component library
              called ChakraUI - also very easy to use, and helps get component
              interactions easily started without much extra effort on your
              part.
            </Text>
            <Heading as="h3" size="sm">
              4. react-slick
            </Heading>

            <Text fontSize="md">
              There was no great options for the Carousel I wanted to include to
              feature some of my projects, so I used react-slick. There were
              some ups-and-downs with customizing the carousel, as the
              out-of-the-box react-slick version has significant responsiveness
              issues given my use case. Looking back, it may actually have been
              easier to just implement the logic myself, and if I have time, I'd
              love to contribute to react-slick as it is an open-source project
              to address some of the responsiveness issues I faced.
            </Text>

            <Heading as="h3" size="sm">
              5. react-icons
            </Heading>

            <Text fontSize="md">
              This one's probably the most well-known but all the icons I needed
              for the site, I imported through react-icons.
            </Text>

            <br />
            <Heading as="h3" size="md">
              Overall Reflection
            </Heading>

            <Text fontSize="md">
              I think the site looks a lot better compared to the old one, with
              some more color. Overhauling the code structure also means it
              should be easier to maintain. I also added a lot more screenshots
              and images of projects, so ideally, for a recruiter going through
              the site, they would not need to click on any extra links to get a
              sense of each project. Using ChakraUI made dealing with
              responsiveness easier too, as it had some nice ways to intuitively
              code in behavior for various screen size breaks. Also, using the
              component libraries made development so much faster, as I was able
              to finish the site in about a week. Granted, a lot of the text
              content was previously written, but it was still much faster than
              I expected a complete overhaul of the site would take.
            </Text>

            <br />
            <Heading as="h3" size="md">
              Retro / Action Items 📝
            </Heading>
            <br />
            <Heading as="h3" size="sm">
              1. Not fully fleshing out the design first
            </Heading>

            <Text fontSize="md">
              While I did have a bare-bones wireframe of squares and rectangles
              drawn out on my notebook, I did not have a really complete design,
              and that came back to bite me when it came to finalizing what the
              landing page would look like. Initially, I thought I'd just have a
              quick blurb of who I was but it looked kinda bad to just have a
              wall of text. I thought about moving the profile picture up so it
              was text + picture, but then that would leave the skills section
              looking rather weird. I finally settled on using a code terminal
              as a tongue-in-cheek way of introducing who I was, and while it
              was still a wall of text, hopefully it made things at least
              somewhat more interesting than just straightforward English. I
              think if I had worked off of a more fleshed-out design, I wouldn't
              have to make these large design decisions after significant dev
              work has been put in.
            </Text>

            <br />

            <Heading as="h3" size="sm">
              2. Not testing for responsiveness at the start
            </Heading>

            <Text fontSize="md">
              I had the bad habit of "let's make it look good for desktop first,
              and then I'll test on mobile" for this project. After I finished
              it on desktop, there were so many little things that I needed to
              change, and I wish I had kept responsiveness in mind when I was
              coding it instead of retroactively coming back and adding
              responsiveness to the code. I also had an issue on Chrome on IOS
              where there would be a bottom menu bar part of Chrome that would
              pop up when you scroll up. This caused the viewport height to
              change, which would cause my whole site to "jump" up. I was able
              to fix it by making the body element have a fixed position, which
              in turn caused the bottom nav bar to be always present. i wish I
              had discovered this issue early on and worked around it, instead
              of retroactively changing it and hoping nothing would break.
            </Text>
          </Stack>
        </Center>
      </Center>
    </ChakraProvider>
  );
}

export default Blogs;
