import "../App.css";
import {
  ChakraProvider,
  Stack,
  Heading,
  UnorderedList,
  Text,
  ListItem,
  Center,
  Image,
} from "@chakra-ui/react";
import BlogNavBar from "./blogNavBar";
import oldSite1 from "../images/blog/oldSite1.png";
import oldSite2 from "../images/blog/oldSite2.png";

function Blogs() {
  return (
    <ChakraProvider>
      <BlogNavBar />
      <Center width="98vw">
        <Center width={{ base: `70vw`, sm: `70vw`, lg: "50vw" }}>
          <Stack spacing={2} className={"py-10"}>
            <Heading as="h3" size="lg">
              Reflecting on My Old Portfolio Site
            </Heading>

            <Text fontSize="md">July 10, 2023</Text>
            <br />
            <Text fontSize="md">
              Since this is my first blog entry, I wanted to first touch on the
              motivation behind starting a blog on my portfolio site. Writing
              was not my strongest field growing up, and writing better has been
              one of my personal development goals for a long time. To achieve
              that personal goal, I want to try to write one blog entry per
              week, but I'm sure some weeks will slip through the cracks when
              summer break ends. I want to have the entries be more
              reflection-based and document the journey of learning different
              things.
              <br />
              <br />I made my personal website freshman year as a way to get
              hands-on experience with web development. It is not really the
              greatest site in the world, but I did learn a lot from the
              experience. Now that I'm going into senior year, I want to revamp
              my site, and I wanted to take a look back at what I liked and
              didn't like in order to make my personal site 2.0 the best it can
              possibly be. I've added some screenshots of my old site as well so
              that if you are trying to make your own site, you won't make the
              same mistakes that I made.
            </Text>

            <br />

            <Image src={oldSite1} border="1px" />

            <br />

            <Heading as="h3" size="md">
              The 👍 and 🥳
            </Heading>
            <br />

            <Heading as="h3" size="sm">
              1. It presents info fast
            </Heading>

            <Text fontSize="md">
              There aren't too many flashy animations or eye-popping design
              choices here, which I think is something I liked. I believe in
              substance over style - no amount of styling is going to cover up a
              website without content, and the purpose of my personal site is to
              showcase my abilities and experiences, so I liked the direct
              approach my old site has, even if I'm not gonna win any art awards
              for it.
            </Text>

            <Heading as="h3" size="sm">
              2. It is surprisingly responsive?
            </Heading>

            <Text fontSize="md">
              Considering that I made this at the very beginning of my college
              career, I was pleasantly surprised with how responsive the site
              was.
            </Text>

            <Heading as="h3" size="sm">
              3. Providing links
            </Heading>

            <Text fontSize="md">
              I believe in show not tell. Instead of telling someone about a
              project, it may be easier to just show them, and I liked how each
              project was able to link to the repo / demo corresponding to the
              project.
            </Text>
            <br />

            <Image src={oldSite2} border="1px" />

            <br />
            <Heading as="h3" size="md">
              The 👎 and 😔
            </Heading>
            <br />

            <Heading as="h3" size="sm">
              1. Not using component libraries
            </Heading>

            <Text fontSize="md">
              While coding the site from raw HTMl and CSS gave me a lot more
              control and also helped me learn some valuable skills initially,
              it becomes a pain to make changes as well as deal with
              responsiveness. For my site 2.0, I want to leverage frameworks and
              libraries out there so that my job is a bit easier. Why reinvent
              the wheel?
            </Text>

            <Heading as="h3" size="sm">
              2. Not being single-paged
            </Heading>

            <Text fontSize="md">
              Looking at Google Analytics, half of the visitors of the site were
              on their phones. With my old site, you would need to navigate page
              by page via the side navigation bar. I should make my site one
              single-page that someone on a phone could just keep scrolling with
              minimal user interference.
            </Text>

            <Heading as="h3" size="sm">
              3. Basically just my resume
            </Heading>

            <Text fontSize="md">
              My site right now is basically my resume on crack. While that is a
              valid way of going about a personal site, I want to at least try
              to make it a bit more personal, which could be accomplished by
              adding a blog component to it. I kind of always viewed my site as
              my corner of the internet, but right now, the site is basically
              functioning as LinkedIn, which is not ideal. I should use the site
              to showcase other aspects of my life, not just the software
              engineering aspect.
            </Text>

            <Heading as="h3" size="sm">
              4. No screenshots
            </Heading>

            <Text fontSize="md">
              I liked the links for each project, but I can do better if I can
              integrate project screenshots directly into the site. That way,
              visitors can see the projects and visualize them without even
              clicking the link. It will also make my site not just a wall of
              text.
            </Text>

            <Heading as="h3" size="sm">
              4. It's black and white
            </Heading>

            <Text fontSize="md">
              I'm no artist which is one of the reasons why the site is black
              and white, I should at least attempt to add some color for version
              2.0.
            </Text>

            <br />
            <Heading as="h3" size="md">
              Retro / Action Items 📝
            </Heading>
            <UnorderedList>
              <ListItem>
                Make my coding life easier through using existing frameworks and
                component libraries
              </ListItem>
              <ListItem>
                Make my site easier to navigate through adding screenshots and
                making it single-page
              </ListItem>
              <ListItem>
                Make my site not just a resume by adding a blog section
              </ListItem>
              <ListItem>
                Make my site not as dull by attempting to add some color
              </ListItem>
            </UnorderedList>
          </Stack>
        </Center>
      </Center>
    </ChakraProvider>
  );
}

export default Blogs;
