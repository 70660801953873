import "../App.css";

import { HashLink } from "react-router-hash-link";
import { useState, useEffect } from "react";

export default function NavBar() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <div
      className={`navbar bg-base-100 z-50 sticky transitionTop navShadow ${
        visible ? "top-0" : "topNo"
      } `}
    >
      <div className="flex-1">
        <a className="btn btn-ghost normal-case text-xl" href="/">
          Andrew Yuan
        </a>
      </div>
      <div className="flex-none">
        <ul className="menu menu-horizontal px-1">
          <li>
            <a href="/#/blog">Blog</a>
          </li>
          <li>
            <HashLink smooth to="#skills">
              About
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#FeaturedProjects">
              Highlighted Work
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#Experiences">
              Experiences
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#Extracurriculars">
              Involvement
            </HashLink>
          </li>
          <li>
            <HashLink smooth to="#AllProjects">
              All Projects
            </HashLink>
          </li>
        </ul>
      </div>
    </div>
  );
}
