export default function LandingPage() {
  return (
    <div className="hero min-h-screen bg-base-200 gradient">
      <div className="hero-content lg:flex-row-reverse w-5/6">
        <div className="terminal">
          <p className="terminalTop">Welcome to my website!</p>

          <div className="code-div">
            <p className="code">
              <span class="darkBlue">class</span>{" "}
              <span class="green">andrew_yuan</span>:
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;university ={" "}
              <span class="lightBlue">
                "University of Maryland, College Park"
              </span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;graduation ={" "}
              <span class="lightBlue">"May 2024"</span>
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;majors = [
              <span class="lightBlue">"Computer Science"</span>,{" "}
              <span class="lightBlue">"Mathematics"</span>]
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;recent_experiences = [
              <span class="lightBlue">"Workiva"</span>,{" "}
              <span class="lightBlue">"Amazon Web Services"</span>,{" "}
              <span class="lightBlue">"Hack4Impact"</span>]
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;<span class="darkBlue">def</span>{" "}
              <span class="green">what_i_do</span>():
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span class="darkBlue">while</span>&nbsp;
              <span class="purple">True</span>:<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;learn_and_create()
              <br />
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;<span class="darkBlue">def</span>{" "}
              <span class="green">say_welcome</span>():
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span class="lightBlue">print</span>(
              <span class="lightBlue">
                "Welcome to my site! Scroll down to discover more."
              </span>
              )
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
