import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";

import workivaLogo from "../images/workiva.png";
import amazonLogo from "../images/amazon.webp";
import lkcLogo from "../images/lkc.jpg";
import suitsLogo from "../images/suits.webp";
import aplLogo from "../images/apl.webp";

import { Link } from "@chakra-ui/react";

export default function Experiences() {
  return (
    <div>
      <h1 class="text-3xl p-3">Experiences</h1>
      <div class="width-ninety-percent">
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={workivaLogo} alt="workiva" />
                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        Workiva
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                For the summer of 2023, I was a software engineering intern at
                Workiva, a software company that provides cloud solutions for
                business and financial reporting with products used by over 75
                percent of Fortune 500 companies. During the internship, I
                worked for the Build Test Release team, who are in charge of the
                internal continuous integration and continuous deployment
                (CI/CD) tools used by all software engineers at Workiva for
                building, testing, and releasing new features.
                <br />
                <br />I was given the expectations of a standard software
                engineer and actively participating in sprints over the duration
                of the 12-week internship. I was able to contribute to the
                team's initiative in transitioning from legacy build and testing
                systems to a commercial off-the-shelf solution in the form of
                integrating with Github Actions. I was also able to be part of
                the Release Pipeline redesign initiative, contributing to a
                complete overhaul of the existing Release Pipeline Management
                system.
              </p>
              <br />
              <p> Tasks I worked on:</p>
              <UnorderedList>
                <ListItem>
                  Transitioned existing repositories from being built with{" "}
                  <b>Dockerfiles</b> to use Github Actions
                </ListItem>
                <ListItem>
                  Modified custom <b>Kubernetes</b> runners to handle various
                  bugs and issues that stem from running Github Actions
                </ListItem>
                <ListItem>
                  Creating custom Github Actions to facilitate retrieval of
                  build artifacts using <b>Typescript</b>
                </ListItem>
                <ListItem>
                  Added support for scoped NPM packages to Workiva's dependency
                  tracking system
                </ListItem>
                <ListItem>
                  Coded various API endpoints to be used by the revamped Release
                  Pipeline system using <b>Python</b>
                </ListItem>
                <ListItem>
                  Ensured code quality through functional tests and unit tests
                  using <b>Jest</b> and <b>Pytest</b>
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={amazonLogo} alt="amazon" />
                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        Amazon Web Services
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                For the summer of 2022, I was a software development intern at
                Amazon Web Services, a subsidiary of Amazon that provides
                on-demand cloud computing platforms and APIs. During the
                internship, I worked for the AWS Outposts team. The{" "}
                <Link color="teal.500" href="https://aws.amazon.com/outposts">
                  Outposts Console
                </Link>{" "}
                allows customers to extend and run native AWS services on
                premises through purchasing Outposts servers, which would be
                useful for consumer cases where low latency and local data
                processing is especially important. My intern project was to
                create a brand-new Map View feature on the Outposts Console,
                providing value to customers through providing insights and
                visualizing data in a more intuitive manner. Previously,
                customers could only see their list of Outposts Site locations
                in a gridded table, with the site addresses in text form. My
                developed feature instead plotted a customer's sites across a
                provisioned map with markers, allowing customers to easily
                visualize the geographical spread of their sites. Customers can
                also click on these markers to bring up information and links
                relevant to each site.
              </p>

              <br />
              <p> Tasks I worked on:</p>
              <UnorderedList>
                <ListItem>
                  Used <b>React</b> and <b>Typescript</b> to create the
                  aforementioned Map View feature
                </ListItem>
                <ListItem>
                  Worked with the Application Security team to get my feature to
                  private beta testing
                </ListItem>
                <ListItem>
                  Integrated APIs from <b>Amazon Location Services</b> into the
                  existing Outposts Console codebase
                </ListItem>
                <ListItem>
                  Collaborated with Product Managers to ensure the feature
                  accurately addressed customer needs
                </ListItem>
                <ListItem>
                  Wrote unit tests using <b>Enzyme</b> and <b>Jest</b> to ensure
                  code accuracy and code quality
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={lkcLogo} alt="lkc" />
                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        LKC Technologies
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                For the summer of 2021, I was a software engineering intern at{" "}
                <Link color="teal.500" href="https://lkc.com">
                  LKC Technologies
                </Link>
                , a bio-technology company specializing in producing
                electroretinography (ERG) medical equipment and its associated
                software. I was the sole intern and was given two projects over
                the course of the summer. First, I worked on the manufacturing
                software used in-house by QA engineers to conduct quality
                control tests on all outgoing equipment. I consolidated multiple
                QA tools used into one C# Desktop application, helping decrease
                QA testing time by 60%. Next, I designed a mobile app for a
                medical data matrix encoder. The app previously only existed on
                desktops, and my mobile app version provided more convenience
                and flexibility for LKC consumers globally.
              </p>
              <br />
              <p> Tasks I worked on:</p>
              <UnorderedList>
                <ListItem>
                  Created a multi-threaded <b>C#</b> desktop application to test
                  the UTAS (Universal Testing and Analysis System) prior to
                  shipping
                </ListItem>
                <ListItem>
                  Automated certain tests that were previously done manually to
                  increase the efficiency of the calibration process
                </ListItem>
                <ListItem>
                  Communicated and interfaced with LKC custom equipment as well
                  as third party light meters to conduct calibration tests
                </ListItem>
                <ListItem>
                  Created an automated archive system that would keep track of
                  test results in JSON files and other Device History Record
                  forms
                </ListItem>
                <ListItem>
                  Transferred a data encoder matrix application written in C++
                  to a mobile app using <b>QT Studio</b>
                </ListItem>
                <ListItem>
                  Published the application to the{" "}
                  <Link
                    color="teal.500"
                    href="https://apps.apple.com/us/app/reteval-barcode-generator/id1582056600?platform=iphone"
                  >
                    iOS App Store
                  </Link>{" "}
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={suitsLogo} alt="h4ilogo" />
                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        Team TerpSUITS
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                During the Fall 2020 to Spring 2021 school year, I took part in
                the 2020{" "}
                <Link color="teal.500" href="https://www.nasa.gov/solve/SUITS">
                  NASA SUITS
                </Link>{" "}
                challenge, an international challenge where teams develop an
                Augmented Reality interface to be used by astronauts in future
                lunar missions, as part of the Team TerpSUITS team. You can
                think of the project as designing JARVIS for astronauts. Some
                tasks our interface tackled included finding the best navigation
                route for the astronaut, as well as communicating important
                biological telemetry data back to the astronaut through the
                interface. We used the <b>Unity Game Engine</b> and <b>C#</b> in
                order to build the interface designed for Microsoft Hololens
                headsets.
              </p>
              <br />
              <p> Tasks that I was specifically in charge of:</p>
              <UnorderedList>
                <ListItem>
                  Calculating the best route from Point A to Point B on the
                  lunar surface
                </ListItem>
                <ListItem>
                  Marking out danger points (such as craters) and dynamically
                  changing the navigation route to avoid these danger points
                </ListItem>
                <ListItem>
                  Implementing a system for astronauts to plant virtual flags in
                  order to bookmark locations of interest
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <div class="flex">
                    <img class="logo" src={aplLogo} alt="apl" />
                    <div class="marginAuto">
                      <h1 class="text-2xl paddingleft3 paddingBottomSmall">
                        John Hopkins Applied Physics Lab
                      </h1>
                    </div>
                  </div>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <p>
                During the Fall 2019 to Spring 2020 school year, I was an ASPIRE
                intern at{" "}
                <Link color="teal.500" href="https://www.jhuapl.edu/">
                  John Hopkins Applied Physics Lab
                </Link>
                , the United State's largest univeresity affiliated research
                lab. I worked with my supervisor to analyze the encryption
                methods and handshake protocols utilized by Whatsapp, which
                boasts end-to-end encryption for users. We studied and proved
                Whatsapp's whitepapers claims and also tried to find any
                vulnerabilties in the handshake protocol.
              </p>
              <br />
              <p> Tasks that I worked on:</p>
              <UnorderedList>
                <ListItem>
                  Used Wireshark and air-packet capture devices to capture and
                  analyze data packets being sent between two phones
                </ListItem>
                <ListItem>
                  Wrote parsers in <b>Python</b> to parse out message data for
                  each captured packet
                </ListItem>
                <ListItem>
                  Created diagrams of the handshake protocol and wrote a final
                  report on our findings
                </ListItem>
                <ListItem>
                  Standardized testing and data packet capturing procedures for
                  future interns to follow
                </ListItem>
              </UnorderedList>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
