import profilePic from "../images/pic2.webp";
import Resume from "./AndrewYuanResume.pdf";
import { Link } from "@chakra-ui/react";

export default function Skills() {
  return (
    <div>
      <h1 class="text-3xl p-3">Skills</h1>
      <div class="mySkillsDiv">
        <img class="personalPic" src={profilePic} alt="profile Pic" />
        <div>
          <div class="box">
            <div>Javascript</div>
            <div>Typescript</div>
            <div>React</div>
            <div>Node.js</div>
            <div>Express.js</div>
            <div>Dart</div>
            <div>Flutter</div>
            <div>HTML</div>
            <div>CSS</div>
            <div>Swift</div>
            <div>HTML</div>
            <div>CSS</div>
            <div>Python</div>
            <div>C#</div>
            <div>Go</div>
            <div>PostgreSQL</div>
            <div>Firebase</div>
            <div>Django</div>
            <div>Docker</div>
            <div>Kubernetes</div>
          </div>

          <br />

          <div>
            I've loved building different things since I was a kid, and that
            passion carried over to <b>software engineering</b> when I got my
            first laptop when I was 10-years-old. I love creating things that
            make change around me - be it through personal initiatives or
            extracurricular activities at the University.
          </div>
          <br />

          <div>
            In the moments I'm free, you can find me playing the piano (I've
            played since I was 7 years old), and I've also recently created a{" "}
            <Link
              color="teal.500"
              href="https://www.youtube.com/channel/UC7CHl9rf67MUiwztd4Lz_5w"
            >
              YouTube channel
            </Link>{" "}
            for hip hop beats I make under my producer alter-ego NDRW. I also
            enjoy watching A LOT of movies and TV (find me on{" "}
            <Link color="teal.500" href="https://letterboxd.com/andrewyuantw/">
              Letterboxd
            </Link>{" "}
            and{" "}
            <Link
              color="teal.500"
              href="https://www.serializd.com/user/andrewyuantw/profile"
            >
              Serializd
            </Link>{" "}
            for the occasional reviews) and can often talk about movies for
            hours. I have also started a new blog where I write about projects
            and other things that interest me. You can see the blog{" "}
            <Link color="teal.500" href="/#/blog">
              here
            </Link>
            .
          </div>

          <div class="discover my-6">
            <a class="customButton" href={Resume}>
              Resume
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
